var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Link } from "react-router-dom";
import { Auth } from "aws-amplify";
import axios from "axios";
import { message } from "antd";
import * as process from "process";
export var getDateFormat = function () {
    return 'YYYY-MM-DD';
};
export var errorMessage = function (error) {
    if (error.response && error.response.data && error.response.data.error) {
        void message.error(error.response.data.error, 10);
    }
    else if (error.response && error.message) {
        void message.error(error.message, 10);
    }
};
export var getLicenseApiUrl = function (license, isLog) {
    if (isLog === void 0) { isLog = false; }
    var log = isLog ? '/log/?limit=20' : '';
    return process.env.LICENSE_URL + license + log;
};
export var getDemoshopsLogUrl = function (id) {
    return process.env.API_BASE_URL + 'log/' + (id !== null && id !== void 0 ? id : '') + '?offset=0,100';
};
export var getDemoshopsCreateUrl = function () {
    return "".concat(process.env.API_BASE_URL, "clone/");
};
export var getDemoshopRemoveUrl = function (id) {
    return "".concat(process.env.API_BASE_URL, "clone/").concat(id, "/");
};
export var isObject = function (object) {
    return !Array.isArray(object) && object === Object(object);
};
export var capitalizeFirstLetter = function (string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
};
export var getObjectValuesAsHTML = function (object) {
    return Object.keys(object)
        .filter(function (key) { return object[key] !== ''; })
        .map(function (key) {
        var objectVal = object[key];
        if (isObject(objectVal)) {
            objectVal = Object.keys(objectVal)
                .filter(function (__key) { return objectVal[__key] !== ''; })
                .map(function (__key) {
                return "<p>".concat(capitalizeFirstLetter(__key.replace('_', ' ')), ": ").concat(objectVal[__key], "</p>");
            }).join('');
        }
        return "<p>".concat(capitalizeFirstLetter(key.replace('_', ' ')), ": ").concat(objectVal, "</p>");
    }).join('');
};
export var isTimestamp = function (number) {
    return !Number.isNaN(number) && Number.isFinite(number) && /^\d+\.?\d+$/.test(number.toString());
};
export var getDateHTML = function (timestamp) {
    console.log('timestamp', timestamp);
    if (!isTimestamp(timestamp)) {
        return null;
    }
    var milliseconds = Number(timestamp) * 1000;
    var date = new Date(milliseconds);
    return date.toLocaleString();
};
export var renderRecord = function (record, key, isLink) {
    if (isLink === void 0) { isLink = false; }
    var recordValue = record[key];
    if (isLink) {
        return _jsx(Link, __assign({ to: "".concat(record.id) }, { children: record.id }));
    }
    if (typeof recordValue === 'number' && isTimestamp(recordValue)) {
        return getDateHTML(recordValue);
    }
    if (recordValue && isObject(recordValue)) {
        return _jsx("span", { dangerouslySetInnerHTML: { __html: isObject(recordValue) ? getObjectValuesAsHTML(recordValue) : '' } });
    }
    if (record && Array.isArray(recordValue)) {
        var recordVal = record[key];
        if (recordVal && Array.isArray(recordVal)) {
            return _jsx("span", { dangerouslySetInnerHTML: { __html: recordVal.join(' ') } });
        }
    }
    if (typeof recordValue === "boolean") {
        return recordValue.toString();
    }
    return _jsx(_Fragment, { children: recordValue });
};
export var logOut = function (setUnAuthenticated) { return __awaiter(void 0, void 0, void 0, function () {
    var error_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                localStorage.removeItem('token');
                localStorage.removeItem('user');
                localStorage.removeItem('jwt-token');
                return [4 /*yield*/, Auth.signOut()];
            case 1:
                _a.sent();
                setUnAuthenticated();
                return [3 /*break*/, 3];
            case 2:
                error_1 = _a.sent();
                console.log('error signing out: ', error_1);
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var delay = function (timeout) {
    return new Promise(function (resolve) { return setTimeout(resolve, timeout); });
};
export var getResponseFromLiveApi = function (jwt) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        return [2 /*return*/, new Promise(function (resolve, reject) { return __awaiter(void 0, void 0, void 0, function () {
                var response, error_2;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            _a.trys.push([0, 2, , 3]);
                            return [4 /*yield*/, axios.get(process.env.API_URL, {
                                    headers: {
                                        'Authorization': jwt
                                    }
                                })];
                        case 1:
                            response = _a.sent();
                            if (response) {
                                resolve(response.data);
                            }
                            return [3 /*break*/, 3];
                        case 2:
                            error_2 = _a.sent();
                            reject(error_2);
                            return [3 /*break*/, 3];
                        case 3: return [2 /*return*/];
                    }
                });
            }); })];
    });
}); };
export var getToken = function (xAuth) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        return [2 /*return*/, new Promise(function (resolve, reject) { return __awaiter(void 0, void 0, void 0, function () {
                var tokenResponse, error_3;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            _a.trys.push([0, 2, , 3]);
                            return [4 /*yield*/, axios.get(process.env.API_BASE_URL + 'token/', {
                                    headers: {
                                        'x-auth': xAuth
                                    }
                                })];
                        case 1:
                            tokenResponse = _a.sent();
                            if (tokenResponse) {
                                resolve(tokenResponse.data);
                            }
                            return [3 /*break*/, 3];
                        case 2:
                            error_3 = _a.sent();
                            reject(error_3);
                            return [3 /*break*/, 3];
                        case 3: return [2 /*return*/];
                    }
                });
            }); })];
    });
}); };
export var isTokenExpired = function (expire) {
    var dateNow = new Date().getTime();
    var expireDate = new Date(expire * 1000).getTime();
    return expireDate < dateNow;
};
export var isValidJSON = function (json) {
    if (typeof json !== "string") {
        return false;
    }
    try {
        JSON.parse(json);
        return true;
    }
    catch (error) {
        return false;
    }
};
export var getApiData = function (_a) {
    var cb = _a.cb, jwt = _a.jwt, setUnAuthenticated = _a.setUnAuthenticated;
    return __awaiter(void 0, void 0, void 0, function () {
        var jwtToken, localToken, token, apiDataResponse, tokenData, tokenDataResponse, apiData, error_4;
        var _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    jwtToken = jwt ? jwt : ((_b = localStorage.getItem('jwt-token')) !== null && _b !== void 0 ? _b : null);
                    localToken = localStorage.getItem('token');
                    token = localToken && isValidJSON(localToken) ? JSON.parse(localToken) : null;
                    if (!jwtToken) return [3 /*break*/, 8];
                    _c.label = 1;
                case 1:
                    _c.trys.push([1, 7, , 8]);
                    return [4 /*yield*/, getResponseFromLiveApi(jwtToken)];
                case 2:
                    apiDataResponse = _c.sent();
                    if (!(apiDataResponse && apiDataResponse.X_AUTH)) return [3 /*break*/, 6];
                    tokenData = void 0;
                    if (!(token && !isTokenExpired(token.expires))) return [3 /*break*/, 3];
                    tokenData = {
                        token: token.token,
                        expires: token.expires
                    };
                    return [3 /*break*/, 5];
                case 3: return [4 /*yield*/, getToken(apiDataResponse.X_AUTH)];
                case 4:
                    tokenDataResponse = _c.sent();
                    tokenData = {
                        token: tokenDataResponse.token,
                        expires: tokenDataResponse.expires
                    };
                    localStorage.setItem('token', JSON.stringify(tokenData));
                    _c.label = 5;
                case 5:
                    apiData = __assign(__assign({}, apiDataResponse), { tokenData: tokenData });
                    cb(apiData);
                    _c.label = 6;
                case 6: return [3 /*break*/, 8];
                case 7:
                    error_4 = _c.sent();
                    console.error('getApiData error or token error', error_4);
                    Auth.signOut().then(function () {
                        localStorage.clear();
                        setUnAuthenticated();
                    });
                    return [3 /*break*/, 8];
                case 8: return [2 /*return*/];
            }
        });
    });
};
export var getParams = function (filters) {
    if (Object.keys(filters).length === 0)
        return '';
    return Object.keys(filters).filter(function (param) { return filters[param]; }).map(function (param) { return param + "=" + filters[param]; }).join('&');
};
export var queryStringSerialize = function (obj, prefix) {
    var query = [], p;
    console.log('obj', obj);
    for (p in obj) {
        if (obj.hasOwnProperty(p) && obj[p]) {
            var key = isNaN(parseInt(p, 10)) ? p : "";
            var k = prefix ? prefix + "[" + key + "]" : p;
            var value = obj[p];
            if (value !== null && !Array.isArray(value) && typeof value === "object") {
                query.push(queryStringSerialize(value, k));
            }
            else {
                query.push(k + "=" + value);
            }
        }
    }
    return query.join("&");
};
export function formatDate(date) {
    if (date === void 0) { date = new Date(); }
    var year = date.toLocaleString('default', { year: 'numeric' });
    var month = date.toLocaleString('default', {
        month: '2-digit',
    });
    var day = date.toLocaleString('default', { day: '2-digit' });
    return [year, month, day].join('-');
}
