var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useState } from 'react';
import { ClientsEditColumnsCheckboxes } from "@/pages/Dashboard/Clients/ClientsEditColumnsPopup/ClientsEditColumnsCheckboxes/ClientsEditColumnsCheckboxes";
import { SettingFilled } from "@ant-design/icons";
import { Button, Popover } from "antd";
import "./ClientsEditColumnsPopup.scss";
export var ClientsEditColumnsPopup = function (_a) {
    var clients = _a.clients, setColumns = _a.setColumns;
    var _b = useState(false), open = _b[0], setOpen = _b[1];
    var handleOpenChange = function (newOpen) {
        setOpen(newOpen);
    };
    return (_jsx(Popover, __assign({ placement: 'bottomLeft', arrow: false, content: _jsx(ClientsEditColumnsCheckboxes, { clients: clients, setColumns: setColumns }), title: "", trigger: "click", open: open, onOpenChange: handleOpenChange }, { children: _jsx(Button, __assign({ icon: _jsx(SettingFilled, {}) }, { children: "Edit columns" })) })));
};
