var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { getObjectValuesAsHTML, isObject } from "@helpers";
import { Form, Input } from "antd";
import { useEffect } from 'react';
import { useClients } from "@/hooks/useClients/useClients";
import { Spinner } from "@components";
import { useOutletContext, useParams } from "react-router-dom";
export var Client = function () {
    var _a;
    var apiData = useOutletContext();
    var id = useParams().id;
    var _b = useClients((_a = apiData === null || apiData === void 0 ? void 0 : apiData.STRIPE_KEY) !== null && _a !== void 0 ? _a : ''), client = _b.client, getClients = _b.getClients, loading = _b.loading;
    useEffect(function () {
        void getClients({ type: 'retrieve', id: id });
    }, []);
    if (loading) {
        return _jsx(Spinner, {});
    }
    if (Object.keys(client).length === 0) {
        return _jsx("div", { children: "There are no client for this id" });
    }
    return (_jsxs(_Fragment, { children: [_jsx("h2", { children: client.name }), _jsx(Form, __assign({ layout: 'vertical' }, { children: Object.keys(client).map(function (key) {
                    var clientValue = client[key];
                    var value = isObject(clientValue) ? getObjectValuesAsHTML(clientValue) : (typeof clientValue === 'string' ? clientValue : undefined);
                    return (_jsx(Form.Item, __assign({ label: key, initialValue: clientValue }, { children: _jsx(Input, { value: value, readOnly: true }) }), key));
                }) }))] }));
};
