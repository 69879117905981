var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useContext } from 'react';
import { Outlet } from 'react-router-dom';
import { ApiDataContext, LayoutWrapper } from '@components';
export var Dashboard = function () {
    var apiDataContext = useContext(ApiDataContext);
    return (_jsx(LayoutWrapper, __assign({ hasSidebar: true }, { children: _jsx(Outlet, { context: apiDataContext === null || apiDataContext === void 0 ? void 0 : apiDataContext.apiData }) })));
};
