var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef, useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import { Button, Input, Space } from "antd";
import Highlighter from "react-highlight-words";
export function useSearch(_a) {
    var search = _a.search, reset = _a.reset, dataIndex = _a.dataIndex, setFilters = _a.setFilters, currentView = _a.currentView;
    var _b = useState(''), searchText = _b[0], setSearchText = _b[1];
    var _c = useState(''), searchedColumn = _c[0], setSearchedColumn = _c[1];
    var searchInput = useRef(null);
    var sessionFilters = sessionStorage.getItem("filters_".concat(currentView)) ? sessionStorage.getItem("filters_".concat(currentView)) : null;
    var defaultFilters = sessionFilters ? JSON.parse(sessionFilters) : {};
    var handleSearch = function (selectedKeys, confirm, dataIndex) {
        var searchValue = selectedKeys[0];
        confirm();
        setSearchText(searchValue);
        if (search) {
            search(dataIndex, searchValue);
        }
        setSearchedColumn(dataIndex);
    };
    var handleReset = function (clearFilters, confirm) {
        if (reset) {
            reset();
        }
        clearFilters();
        confirm();
        if (setFilters) {
            setFilters(function (filters) {
                var _a = filters, _b = dataIndex, _ = _a[_b], rest = __rest(_a, [typeof _b === "symbol" ? _b : _b + ""]);
                return rest;
            });
        }
        setSearchText('');
    };
    return ({
        filterDropdown: function (_a) {
            var setSelectedKeys = _a.setSelectedKeys, selectedKeys = _a.selectedKeys, confirm = _a.confirm, clearFilters = _a.clearFilters;
            useEffect(function () {
                var value = defaultFilters.filters && defaultFilters.filters[dataIndex] ? defaultFilters.filters[dataIndex] : null;
                if (value && Array.isArray(value)) {
                    setSelectedKeys([value[0]]);
                    setSearchText(value[0]);
                    confirm();
                }
            }, []);
            return (_jsxs("div", __assign({ style: { padding: 8 }, onKeyDown: function (e) { return e.stopPropagation(); } }, { children: [_jsx(Input, { ref: searchInput, placeholder: "Search ".concat(dataIndex.toString()), value: selectedKeys[0], onChange: function (e) { return setSelectedKeys(e.target.value ? [e.target.value] : []); }, onPressEnter: function () { return handleSearch(selectedKeys, confirm, dataIndex); }, style: { marginBottom: 8, display: 'block' } }), _jsxs(Space, { children: [_jsx(Button, __assign({ type: "primary", onClick: function () { return handleSearch(selectedKeys, confirm, dataIndex); }, icon: _jsx(SearchOutlined, {}), size: "small", style: { width: 90 } }, { children: "Search" })), _jsx(Button, __assign({ onClick: function () {
                                    if (clearFilters) {
                                        handleReset(clearFilters, confirm);
                                    }
                                }, size: "small", style: { width: 90 } }, { children: "Reset" }))] })] })));
        },
        filterIcon: function (filtered) {
            return (_jsx(SearchOutlined, { style: { color: filtered ? '#1677ff' : undefined } }));
        },
        onFilter: function (value, record) {
            var recordVal = record[dataIndex];
            if (!recordVal) {
                return false;
            }
            return recordVal
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase());
        },
        onFilterDropdownOpenChange: function (visible) {
            if (visible) {
                setTimeout(function () { var _a; return (_a = searchInput.current) === null || _a === void 0 ? void 0 : _a.select(); }, 100);
            }
        },
        render: function (text) {
            return searchedColumn === dataIndex ? (_jsx(Highlighter, { highlightStyle: { backgroundColor: '#ffc069', padding: 0 }, searchWords: [searchText], autoEscape: true, textToHighlight: text ? text.toString() : '' })) : text;
        },
    });
}
