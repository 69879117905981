var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef, useState } from 'react';
import { queryStringSerialize } from "@helpers";
import { DemoshopExportPopup } from "@/pages/Dashboard/Demoshops/DemoshopExportPopup/DemoshopExportPopup";
import { Outlet, useLocation, useOutletContext, useParams } from "react-router-dom";
import { Button, Input, Modal, Popconfirm, Row, Table } from "antd";
import { useDemoshops } from "@hooks";
import { getDemoshopsColumns } from "@data";
import "./Demoshops.scss";
export var Demoshops = function () {
    var _a, _b, _c, _d;
    var apiData = useOutletContext();
    var _e = useDemoshops((_b = (_a = apiData === null || apiData === void 0 ? void 0 : apiData.tokenData) === null || _a === void 0 ? void 0 : _a.token) !== null && _b !== void 0 ? _b : ''), getDemoshops = _e.getDemoshops, demoshops = _e.demoshops, loading = _e.loading, paginationFiltersSorting = _e.paginationFiltersSorting, removeDemoshop = _e.removeDemoshop, changeTerminationProtection = _e.changeTerminationProtection, setPaginationFiltersSorting = _e.setPaginationFiltersSorting;
    var _f = useState([]), selectedRowKeys = _f[0], setSelectedRowKeys = _f[1];
    var _g = useState(false), isBulkEditPopupVisible = _g[0], setIsBulkEditPopupVisible = _g[1];
    var _h = useState(false), isExportPopupVisible = _h[0], setIsExportPopupVisible = _h[1];
    var _j = useState(), data = _j[0], setData = _j[1];
    var location = useLocation();
    var isNewOrLogs = location.pathname.includes('/new') || location.pathname.includes('/logs');
    var id = useParams().id;
    var tableRef = useRef(null);
    var _k = useState({
        offset: 0,
        limit: 20
    }), limitAndOffset = _k[0], seLimitAndOffset = _k[1];
    var defaultColumns = getDemoshopsColumns(removeDemoshop, paginationFiltersSorting, setPaginationFiltersSorting, changeTerminationProtection);
    var onSelectChange = function (newSelectedRowKeys) {
        setSelectedRowKeys(newSelectedRowKeys);
    };
    var bulkRemoveClickHandler = function () {
        selectedRowKeys.forEach(function (licenseKey) {
            void removeDemoshop(licenseKey.toString());
        });
    };
    var rowSelection = {
        selectedRowKeys: selectedRowKeys,
        onChange: onSelectChange,
    };
    var hasSelected = selectedRowKeys.length > 0;
    var tableChange = function (pagination, filters, sorter) {
        var _a, _b;
        var sorting = !Array.isArray(sorter) ? {
            order_by: sorter.order ? sorter.field : undefined,
            order_type: sorter.order,
            dataIndex: (_b = (_a = sorter.column) === null || _a === void 0 ? void 0 : _a.dataIndex) !== null && _b !== void 0 ? _b : 'admin_email'
        } : paginationFiltersSorting.sorting;
        setPaginationFiltersSorting(function (prevState) {
            var _a;
            var state = {
                page: (_a = pagination.current) !== null && _a !== void 0 ? _a : prevState.page,
                filters: __assign(__assign({}, prevState.filters), filters),
                sorting: sorting
            };
            return state;
        });
    };
    var searchHandler = function (e) {
        var searchValue = e.target.value;
        // @ts-ignore
        setPaginationFiltersSorting(function (prevState) {
            return __assign(__assign({}, prevState), { filters: __assign(__assign({}, prevState.filters), { s: searchValue }) });
        });
    };
    useEffect(function () {
        var _a;
        var page = (_a = paginationFiltersSorting.page) !== null && _a !== void 0 ? _a : 1;
        var offset = (page - 1) * limitAndOffset.limit;
        var _b = paginationFiltersSorting.sorting, dataIndex = _b.dataIndex, sorting = __rest(_b, ["dataIndex"]);
        var query = queryStringSerialize(__assign(__assign({}, paginationFiltersSorting.filters), sorting));
        getDemoshops("".concat(offset, ",").concat(limitAndOffset.limit), query).then(function (data) {
            setData(data === null || data === void 0 ? void 0 : data.result);
        });
        if (tableRef.current) {
            setTimeout((function () {
                var _a;
                (_a = tableRef.current) === null || _a === void 0 ? void 0 : _a.scrollIntoView({ behavior: 'smooth' });
            }), 500);
        }
    }, [paginationFiltersSorting]);
    return (isNewOrLogs || id ?
        _jsx(Outlet, { context: apiData }) :
        _jsx("div", __assign({ ref: tableRef }, { children: demoshops.hasOwnProperty('total') && demoshops.hasOwnProperty('result') &&
                _jsxs(_Fragment, { children: [_jsx(Modal, { open: isBulkEditPopupVisible, title: "Bulk edit mode", onOk: function () { return setIsBulkEditPopupVisible(false); }, onCancel: function () { return setIsBulkEditPopupVisible(false); }, centered: true }), _jsx(DemoshopExportPopup, { isExportPopupVisible: isExportPopupVisible, token: (_d = (_c = apiData === null || apiData === void 0 ? void 0 : apiData.tokenData) === null || _c === void 0 ? void 0 : _c.token) !== null && _d !== void 0 ? _d : '', setIsExportPopupVisible: setIsExportPopupVisible }), _jsx("h2", { children: "Demoshops" }), _jsxs(Row, __assign({ style: { marginBottom: '20px' } }, { children: [hasSelected &&
                                    _jsxs(_Fragment, { children: [_jsx(Button, __assign({ style: { marginRight: '20px' }, onClick: function () { return setIsBulkEditPopupVisible(function (prev) { return !prev; }); }, type: 'primary' }, { children: "Bulk edit" })), _jsx(Popconfirm, __assign({ title: "Are you sure that you want to delete all these demo shops? This step is irreversible.", okText: "Yes", onConfirm: bulkRemoveClickHandler, placement: "topRight", cancelText: "No" }, { children: _jsx(Button, __assign({ style: { marginRight: '20px' }, type: 'primary', danger: true }, { children: "Remove selected demoshops" })) }))] }), _jsx(Button, __assign({ style: { marginRight: '10px' }, onClick: function () { return setIsExportPopupVisible(true); }, type: 'primary' }, { children: "Export csv" })), _jsx(Input, { onChange: searchHandler, style: { width: '200px' }, placeholder: 'Search' })] })), _jsx(Table, { dataSource: data, loading: loading, rowSelection: rowSelection, onChange: tableChange, columns: defaultColumns, scroll: {
                                x: true
                            }, rowKey: "id", pagination: {
                                current: paginationFiltersSorting.page,
                                pageSize: limitAndOffset.limit,
                                defaultPageSize: limitAndOffset.limit,
                                showSizeChanger: false,
                                total: demoshops.total
                            } })] }) })));
};
