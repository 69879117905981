var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { Menu } from 'antd';
import { CloudServerOutlined, KeyOutlined, WalletOutlined, } from '@ant-design/icons';
export var SidebarMenu = function () {
    var _a = useState(''), current = _a[0], setCurrent = _a[1];
    var _b = useState(['demoshops-list']), openKeys = _b[0], setOpenKeys = _b[1];
    var sidebarKeys = ['demoshops-list', 'clients-list', 'licenses-list'];
    var location = useLocation();
    useEffect(function () {
        var currentKey = location.pathname
            .replace('/dashboard/', '')
            .replace('/', '-');
        var openKey = sidebarKeys.find(function (item) { return item.split('-')[0].includes(currentKey.split('-')[0]); });
        setCurrent(currentKey);
        if (openKey) {
            setOpenKeys([openKey]);
        }
    }, []);
    var getMenuItem = function (_a) {
        var label = _a.label, key = _a.key, icon = _a.icon, children = _a.children, type = _a.type;
        return { key: key, icon: icon, children: children, label: label, type: type };
    };
    var menuOpenChangeHandler = function (keys) {
        var latestOpenKey = keys.find(function (key) { return openKeys.indexOf(key) === -1; });
        if (latestOpenKey && sidebarKeys.indexOf(latestOpenKey) === -1) {
            setOpenKeys(keys);
        }
        else {
            setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
        }
    };
    var menuClickHandler = function (e) {
        setCurrent(e.key);
    };
    var items = [
        getMenuItem({
            label: 'Demoshops',
            key: 'demoshop-list',
            icon: _jsx(CloudServerOutlined, {}),
            children: [
                getMenuItem({ label: _jsx(NavLink, __assign({ to: "/dashboard/demoshops/" }, { children: "Manage installs" })), key: 'demoshops' }),
                getMenuItem({ label: _jsx(NavLink, __assign({ to: "/dashboard/demoshops/new/" }, { children: "Create new install" })), key: 'demoshops-new' }),
                getMenuItem({ label: _jsx(NavLink, __assign({ to: "/dashboard/demoshops/logs/" }, { children: "View logs" })), key: 'demoshops-logs' }),
            ]
        }),
        getMenuItem({
            label: 'Clients',
            key: 'client-list',
            icon: _jsx(WalletOutlined, {}),
            children: [
                getMenuItem({ label: _jsx(NavLink, __assign({ to: "/dashboard/clients/" }, { children: "Manage clients" })), key: 'clients' }),
                getMenuItem({ label: _jsx(NavLink, __assign({ to: "/dashboard/clients/new/" }, { children: "Setup new client" })), key: 'clients-new' }),
            ]
        }),
        getMenuItem({
            label: 'Servers',
            key: 'server-list',
            icon: _jsx(WalletOutlined, {}),
            children: [
                getMenuItem({ label: _jsx(NavLink, __assign({ to: "/dashboard/servers/" }, { children: "Manage servers" })), key: 'servers' }),
                getMenuItem({ label: _jsx(NavLink, __assign({ to: "/dashboard/servers/new/" }, { children: "Setup new server" })), key: 'servers-new' }),
                getMenuItem({ label: _jsx(NavLink, __assign({ to: "/dashboard/servers/info/" }, { children: "Server info" })), key: 'servers-info' }),
            ]
        }),
        getMenuItem({
            label: 'Licenses',
            key: 'licenses-list',
            icon: _jsx(KeyOutlined, {}),
            children: [
                getMenuItem({ label: _jsx(NavLink, __assign({ to: "/dashboard/licenses/search" }, { children: "Search license" })), key: 'licenses-search' }),
                getMenuItem({ label: _jsx(NavLink, __assign({ to: "/dashboard/licenses/new" }, { children: "Setup new license" })), key: 'licenses-new' })
            ]
        }),
    ];
    return (_jsx(Menu, { theme: "dark", style: { marginTop: '30px' }, onClick: menuClickHandler, openKeys: openKeys, onOpenChange: menuOpenChangeHandler, selectedKeys: [current], mode: "inline", items: items }));
};
