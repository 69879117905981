var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { TerminationProtectionSwitch } from "@/components/TerminationProtectionSwitch/TerminationProtectionSwitch";
import { useDateRange } from "@/hooks/useDateRange/useDateRange";
import { useSearch } from "@/hooks/useSearch/useSearch";
import { CloseCircleOutlined } from "@ant-design/icons";
import { getDateHTML, renderRecord } from "@helpers";
import { Popconfirm } from "antd";
import { Link } from "react-router-dom";
export var getDemoshopsColumns = function (remove, paginationFiltersSorting, setPaginationFiltersSorting, changeTerminationProtection) {
    return [
        {
            title: 'ID',
            dataIndex: 'id',
            render: function (_, record) {
                return _jsx(Link, __assign({ to: "".concat(record.id) }, { children: record.id }));
            }
        },
        __assign({ title: 'Created', dataIndex: 'created', sorter: true, defaultSortOrder: 'descend' }, useDateRange({
            dataIndex: 'created',
            currentView: 'demoshops',
            setPaginationFiltersSorting: setPaginationFiltersSorting,
        })),
        __assign({ title: 'Name', dataIndex: 'cname', sorter: true, defaultSortOrder: paginationFiltersSorting.sorting.dataIndex === 'cname' && paginationFiltersSorting.sorting.order_type ? paginationFiltersSorting.sorting.order_type : undefined }, useSearch({
            dataIndex: 'cname',
            currentView: 'demoshops'
        })),
        __assign(__assign({ title: 'URL', dataIndex: 'label', sorter: true, defaultSortOrder: paginationFiltersSorting.sorting.dataIndex === 'label' && paginationFiltersSorting.sorting.order_type ? paginationFiltersSorting.sorting.order_type : undefined }, useSearch({
            dataIndex: 'label',
            currentView: 'demoshops'
        })), { render: function (_, record) {
                return _jsx("a", __assign({ target: '_blank', href: "https://".concat(record.cname, ".wetail.shop/") }, { children: record.label }));
            } }),
        __assign({ title: 'Email', dataIndex: 'admin_email', sorter: true, defaultSortOrder: paginationFiltersSorting.sorting.dataIndex === 'admin_email' && paginationFiltersSorting.sorting.order_type ? paginationFiltersSorting.sorting.order_type : undefined }, useSearch({
            dataIndex: 'admin_email',
            currentView: 'demoshops'
        })),
        __assign({ title: 'Phone', dataIndex: 'admin_phone', sorter: true, defaultSortOrder: paginationFiltersSorting.sorting.dataIndex === 'admin_phone' && paginationFiltersSorting.sorting.order_type ? paginationFiltersSorting.sorting.order_type : undefined }, useSearch({
            dataIndex: 'admin_phone',
            currentView: 'demoshops'
        })),
        {
            title: 'Termination protection',
            dataIndex: 'termination_protection',
            render: function (_, record) {
                return _jsx(TerminationProtectionSwitch, { changeTerminationProtection: changeTerminationProtection, termination_protection: record.termination_protection, cloneId: record.id });
            },
            width: '50px',
        },
        {
            title: 'Funktion',
            dataIndex: 'action',
            render: function (_, record) { return (_jsx(Popconfirm, __assign({ title: "Are you sure that you want to delete all these demo shops? This step is irreversible.", okText: "Yes", onConfirm: function () { return remove(record.id.toString()); }, placement: "topRight", cancelText: "No" }, { children: _jsx(CloseCircleOutlined, { style: { color: 'red' } }) }))); },
            width: '30px',
        }
    ];
};
export var demoshopsLogsColumns = [
    {
        title: 'ID',
        dataIndex: 'id',
        render: function (_, record) { return _jsx(Link, __assign({ to: "".concat(record.id) }, { children: record.id })); }
    },
    {
        title: 'Size',
        dataIndex: 'size'
    },
    {
        title: 'Updated',
        dataIndex: 'updated'
    }
];
export var licensesLogsColumns = [
    {
        title: 'Accepted',
        dataIndex: 'accepted',
        render: function (_, record) {
            if (record.accepted) {
                return _jsx("span", { className: 'circle success' });
            }
            else {
                return _jsx("span", { className: 'circle error' });
            }
        }
    },
    {
        title: 'Timestamp',
        dataIndex: 'timestamp',
        render: function (_, record) {
            return getDateHTML(+record.timestamp);
        }
    },
    {
        title: 'Domain',
        dataIndex: 'domain'
    },
    {
        title: 'IP',
        dataIndex: 'IP'
    },
    {
        title: 'Denial reason',
        dataIndex: 'denial_reason',
    },
    {
        title: 'License key',
        dataIndex: 'licenseKey'
    }
];
export var defaultClientsColumns = function (searchCallbacks, setFilters) {
    return [
        {
            title: 'id',
            dataIndex: 'id',
            render: function (_, record) { return renderRecord(record, 'id', true); }
        },
        __assign({ title: 'name', dataIndex: 'name', render: function (_, record) { return renderRecord(record, 'name'); } }, useSearch({
            dataIndex: 'name',
            setFilters: setFilters,
            reset: searchCallbacks.reset,
            search: searchCallbacks.search
        })),
        __assign({ title: 'email', dataIndex: 'email', render: function (_, record) { return renderRecord(record, 'email'); } }, useSearch({
            dataIndex: 'email',
            setFilters: setFilters,
            reset: searchCallbacks.reset,
            search: searchCallbacks.search
        })),
        __assign({ title: 'phone', dataIndex: 'phone', render: function (_, record) { return renderRecord(record, 'phone'); } }, useSearch({
            dataIndex: 'phone',
            setFilters: setFilters,
            reset: searchCallbacks.reset,
            search: searchCallbacks.search
        })),
        {
            title: 'object',
            dataIndex: 'object',
            render: function (_, record) { return renderRecord(record, 'object'); },
        },
        {
            title: 'address',
            dataIndex: 'address',
            render: function (_, record) { return renderRecord(record, 'address'); },
        },
        {
            title: 'balance',
            dataIndex: 'balance',
            render: function (_, record) { return renderRecord(record, 'balance'); },
        },
        {
            title: 'created',
            dataIndex: 'created',
            render: function (_, record) { return renderRecord(record, 'created'); },
        },
        {
            title: 'currency',
            dataIndex: 'currency',
            render: function (_, record) { return renderRecord(record, 'currency'); },
        },
        {
            title: 'default_source',
            dataIndex: 'default_source',
            render: function (_, record) { return renderRecord(record, 'default_source'); },
        },
        {
            title: 'delinquent',
            dataIndex: 'delinquent',
            render: function (_, record) { return renderRecord(record, 'delinquent'); },
        },
        {
            title: 'description',
            dataIndex: 'description',
            render: function (_, record) { return renderRecord(record, 'description'); }
        },
        {
            title: 'discount',
            dataIndex: 'discount',
            render: function (_, record) { return renderRecord(record, 'discount'); },
        },
        {
            title: 'invoice_prefix',
            dataIndex: 'invoice_prefix',
            render: function (_, record) { return renderRecord(record, 'invoice_prefix'); },
        },
        {
            title: 'invoice_settings',
            dataIndex: 'invoice_settings',
            render: function (_, record) { return renderRecord(record, 'invoice_settings'); },
        },
        {
            title: 'livemode',
            dataIndex: 'livemode',
            render: function (_, record) { return renderRecord(record, 'livemode'); },
        },
        {
            title: 'metadata',
            dataIndex: 'metadata',
            render: function (_, record) { return renderRecord(record, 'metadata'); },
        },
        {
            title: 'next_invoice_sequence',
            dataIndex: 'next_invoice_sequence',
            render: function (_, record) { return renderRecord(record, 'next_invoice_sequence'); },
        },
        {
            title: 'preferred_locales',
            dataIndex: 'preferred_locales',
            render: function (_, record) { return renderRecord(record, 'preferred_locales'); },
        },
        {
            title: 'shipping',
            dataIndex: 'shipping',
            render: function (_, record) { return renderRecord(record, 'shipping'); },
        },
        {
            title: 'tax_exempt',
            dataIndex: 'tax_exempt',
            render: function (_, record) { return renderRecord(record, 'tax_exempt'); },
        },
        {
            title: 'test_clock',
            dataIndex: 'test_clock',
            render: function (_, record) { return renderRecord(record, 'test_clock'); },
        }
    ];
};
