var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { getApiData } from "@helpers";
import { useAuthStore } from "@state";
import { createContext, useEffect, useState } from 'react';
import { AppRoutes } from "@routes";
import './App.scss';
export var ApiDataContext = createContext(null);
export var App = function () {
    var _a = useState(null), apiData = _a[0], setApiData = _a[1];
    var setUnAuthenticated = useAuthStore(function (state) { return state; }).setUnAuthenticated;
    useEffect(function () {
        void getApiData({
            cb: function (_apiData) {
                setApiData(_apiData);
            },
            setUnAuthenticated: setUnAuthenticated
        });
    }, []);
    return (_jsx(ApiDataContext.Provider, __assign({ value: { apiData: apiData, setApiData: setApiData } }, { children: _jsx(AppRoutes, {}) })));
};
