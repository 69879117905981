var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import { useOutletContext, useParams } from "react-router-dom";
import { Form, Input } from "antd";
import { useDemoshops } from "@hooks";
import { Spinner } from "@components";
export var Demoshop = function () {
    var _a, _b;
    var apiData = useOutletContext();
    var id = useParams().id;
    var _c = useDemoshops((_b = (_a = apiData === null || apiData === void 0 ? void 0 : apiData.tokenData) === null || _a === void 0 ? void 0 : _a.token) !== null && _b !== void 0 ? _b : ''), getDemoshop = _c.getDemoshop, demoshop = _c.demoshop, loading = _c.loading;
    useEffect(function () {
        if (id) {
            void getDemoshop(id);
        }
    }, [id]);
    if (loading) {
        return _jsx(Spinner, {});
    }
    if (Object.keys(demoshop).length === 0) {
        return _jsx("div", { children: "There are no demoshop for this id" });
    }
    return (_jsxs(_Fragment, { children: [_jsx("h2", { children: demoshop.label }), _jsx(Form, __assign({ layout: 'vertical' }, { children: Object.keys(demoshop).map(function (key) {
                    if (typeof key !== 'string') {
                        return '';
                    }
                    return (_jsx(Form.Item, __assign({ label: key, initialValue: demoshop[key] }, { children: _jsx(Input, { value: demoshop[key], readOnly: true }) }), key));
                }) }))] }));
};
