import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import { Table } from "antd";
import { demoshopsLogsColumns } from "@data";
import { useDemoshops } from "@hooks";
import { useOutletContext } from "react-router-dom";
export var DemoshopLogs = function () {
    var _a, _b;
    var apiData = useOutletContext();
    var _c = useDemoshops((_b = (_a = apiData === null || apiData === void 0 ? void 0 : apiData.tokenData) === null || _a === void 0 ? void 0 : _a.token) !== null && _b !== void 0 ? _b : ''), demoshopsLogs = _c.demoshopsLogs, getDemoshopsLogs = _c.getDemoshopsLogs, loading = _c.demoshopsLogsLoading;
    useEffect(function () {
        void getDemoshopsLogs();
    }, []);
    return (_jsxs(_Fragment, { children: [_jsx("h2", { children: "Logs" }), _jsx(Table, { loading: loading, columns: demoshopsLogsColumns, dataSource: demoshopsLogs })] }));
};
