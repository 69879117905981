var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useDemoshops } from "@hooks";
import { Button, Divider, Modal, Progress, Row } from "antd";
import { useEffect, useState } from 'react';
import { Checkbox } from 'antd';
import { CSVLink } from "react-csv";
export var DemoshopExportPopup = function (_a) {
    var token = _a.token, isExportPopupVisible = _a.isExportPopupVisible, setIsExportPopupVisible = _a.setIsExportPopupVisible;
    var _b = useDemoshops(token), getAllDemoshops = _b.getAllDemoshops, allDemoshopLoading = _b.allDemoshopLoading, allDemoshops = _b.allDemoshops, allDemoshopLoadingProgress = _b.allDemoshopLoadingProgress;
    var _c = useState([]), csvData = _c[0], setCsvData = _c[1];
    var _d = useState([]), checkedList = _d[0], setCheckedList = _d[1];
    var _e = useState([]), checkboxOptions = _e[0], setCheckboxOptions = _e[1];
    var _f = useState(false), checkAll = _f[0], setCheckAll = _f[1];
    var sessionStorageDefaultCols = sessionStorage.getItem('export-csv-default-cols');
    var defaultCols = sessionStorageDefaultCols ? JSON.parse(sessionStorageDefaultCols) : null;
    var onCheckAllChange = function (e) {
        setCheckedList(e.target.checked ? checkboxOptions : []);
        setCheckAll(e.target.checked);
    };
    var onChange = function (checkedList) {
        var list = checkedList;
        setCheckedList(list);
        setCheckAll(checkedList.length === checkboxOptions.length);
        sessionStorage.setItem('export-csv-default-cols', JSON.stringify(list));
    };
    useEffect(function () {
        if (checkedList.length > 0) {
            setCsvData([checkedList]);
            allDemoshops.result.forEach(function (demoshop) {
                var demoshopValues = [];
                checkedList.forEach(function (key) { return demoshopValues.push(demoshop[key]); });
                setCsvData(function (state) { return (__spreadArray(__spreadArray([], state, true), [
                    demoshopValues.map(function (item) { return item ? item.toString() : ""; })
                ], false)); });
            });
        }
    }, [checkedList]);
    useEffect(function () {
        if (allDemoshops && allDemoshops.result && allDemoshopLoadingProgress === 100) {
            setCheckboxOptions(Object.keys(allDemoshops.result[0]));
            if (defaultCols && defaultCols.length > 0) {
                setCheckedList(defaultCols);
            }
        }
    }, [allDemoshopLoadingProgress]);
    useEffect(function () {
        if (isExportPopupVisible) {
            void getAllDemoshops();
        }
    }, [isExportPopupVisible]);
    return (_jsx(Modal, __assign({ open: isExportPopupVisible, title: "Export csv settings", footer: _jsxs(Row, { children: [_jsx(Button, __assign({ onClick: function () { return setIsExportPopupVisible(false); } }, { children: "Cancel" })), _jsx(CSVLink, __assign({ className: "ant-btn css-dev-only-do-not-override-15rg2km csv-link ant-btn-primary ".concat(checkedList.length === 0 ? 'disabled' : ''), data: csvData }, { children: "Save csv file" }))] }), okText: 'Save CSV file', onCancel: function () { return setIsExportPopupVisible(false); }, centered: true }, { children: _jsx(Row, { children: allDemoshopLoading ?
                _jsxs(Row, __assign({ justify: 'center', align: 'middle', style: { height: '200px', width: '100%' } }, { children: [_jsx("span", __assign({ style: { marginRight: '20px', display: 'inline-block' } }, { children: "Loading all demoshops, please wait" })), _jsx(Progress, { percent: allDemoshopLoadingProgress, type: 'circle', size: 20 })] })) :
                _jsxs("div", { children: [_jsx("b", __assign({ style: { display: 'block', marginBottom: '10px' } }, { children: "Please chose columns which will be included in csv file" })), _jsxs("div", { children: [_jsx(Checkbox, __assign({ onChange: onCheckAllChange, checked: checkAll }, { children: "Check all" })), _jsx(Divider, { style: { margin: '10px' } }), _jsx(Checkbox.Group, { options: checkboxOptions, value: checkedList, onChange: onChange })] })] }) }) })));
};
