var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Layout } from 'antd';
import { useAuthStore } from '@state';
import { Header, Sidebar, SidebarMenu } from '@components';
export var LayoutWrapper = function (_a) {
    var children = _a.children, _b = _a.hasHeader, hasHeader = _b === void 0 ? true : _b, _c = _a.hasSidebar, hasSidebar = _c === void 0 ? false : _c, _d = _a.hasFooter, hasFooter = _d === void 0 ? false : _d;
    var Content = Layout.Content, Footer = Layout.Footer;
    var navigate = useNavigate();
    var isAuth = useAuthStore(function (state) { return state; }).isAuth;
    useEffect(function () {
        if (!isAuth) {
            navigate('/login');
        }
    }, [isAuth]);
    return (_jsxs(Layout, { children: [hasHeader &&
                _jsx(Header, {}), _jsxs(Layout, { children: [hasSidebar &&
                        _jsx(Sidebar, __assign({ style: { width: '256px' }, className: "site-layout-background" }, { children: _jsx(SidebarMenu, {}) })), _jsx(Content, __assign({ className: "site-layout-background", style: {
                            padding: '20px',
                            margin: 0,
                            minHeight: 'calc(100vh - 64px)',
                        } }, { children: children }))] }), hasFooter &&
                _jsx(Footer, { children: "footer" })] }));
};
