var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect } from 'react';
import { useNavigate, useOutletContext } from "react-router-dom";
import { Button, Form, Input, Space } from 'antd';
import { Spinner } from "@components";
import { useLicense } from "@hooks";
export var LicenseSearch = function () {
    var _a;
    var apiData = useOutletContext();
    var _b = useLicense((_a = apiData === null || apiData === void 0 ? void 0 : apiData.WAPI_TOKEN) !== null && _a !== void 0 ? _a : ''), license = _b.license, loading = _b.loading;
    var navigate = useNavigate();
    var form = Form.useForm()[0];
    var formSubmitHandler = function (values) {
        var searchValue = values.search;
        navigate("/dashboard/licenses/".concat(searchValue));
        form.resetFields();
    };
    useEffect(function () {
        if (!loading && Object.keys(license).length > 0) {
        }
    }, [loading, license]);
    if (loading) {
        return _jsx(Spinner, {});
    }
    return (_jsxs(_Fragment, { children: [_jsx("h2", { children: "Search license" }), _jsx(Form, __assign({ form: form, style: { marginTop: 10 }, onFinish: formSubmitHandler }, { children: _jsxs(Space.Compact, __assign({ style: { width: '25%' } }, { children: [_jsx(Form.Item, __assign({ style: { width: '100%' }, name: 'search' }, { children: _jsx(Input, { placeholder: 'License key' }) })), _jsx(Form.Item, { children: _jsx(Button, __assign({ type: 'primary', htmlType: 'submit' }, { children: "Search" })) })] })) }))] }));
};
