import { jsx as _jsx } from "react/jsx-runtime";
import { Switch } from "antd";
import { useState } from "react";
export var TerminationProtectionSwitch = function (_a) {
    var termination_protection = _a.termination_protection, changeTerminationProtection = _a.changeTerminationProtection, cloneId = _a.cloneId;
    var _b = useState(false), loading = _b[0], setLoading = _b[1];
    var _c = useState(termination_protection === 'yes'), checked = _c[0], setChecked = _c[1];
    var onChange = function (checked) {
        setLoading(true);
        setChecked(checked);
        changeTerminationProtection(cloneId, checked ? 'enable_termination_protection' : 'disable_termination_protection')
            .catch(function (err) {
            console.error('Termination protection error', err);
        })
            .finally(function () {
            setLoading(false);
        });
    };
    return _jsx(Switch, { loading: loading, onChange: onChange, value: checked });
};
