var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useNavigate, useOutletContext } from "react-router-dom";
import { Button, Col, Form, Input, Row } from "antd";
import { useDemoshops } from "@hooks";
import { errorMessage } from "@helpers";
export var DemoshopNew = function () {
    var _a, _b;
    var form = Form.useForm()[0];
    var apiData = useOutletContext();
    var _c = useDemoshops((_b = (_a = apiData === null || apiData === void 0 ? void 0 : apiData.tokenData) === null || _a === void 0 ? void 0 : _a.token) !== null && _b !== void 0 ? _b : ''), loading = _c.loading, createDemoshop = _c.createDemoshop;
    var navigate = useNavigate();
    var onFinish = function (values) {
        createDemoshop(values)
            .then(function (demoshopData) {
            navigate("/dashboard/demoshops/".concat(demoshopData.id));
        })
            .catch(function (error) { return errorMessage(error); });
        form.resetFields();
    };
    return (_jsxs(_Fragment, { children: [_jsx("h2", { children: "Create new install" }), _jsx(Form, __assign({ form: form, onFinish: onFinish, layout: "vertical" }, { children: _jsx(Row, { children: _jsxs(Col, __assign({ span: 12, style: { background: '#ffffff', padding: '20px' } }, { children: [_jsx(Form.Item, __assign({ name: 'cname', required: true, label: 'CNAME' }, { children: _jsx(Input, {}) })), _jsx(Form.Item, __assign({ name: 'admin_email', rules: [
                                    {
                                        type: 'email',
                                        message: 'The input is not valid E-mail!',
                                    },
                                    {
                                        required: true,
                                        message: 'Please input your E-mail!',
                                    },
                                ], label: 'YOUR EMAIL' }, { children: _jsx(Input, {}) })), _jsx(Form.Item, __assign({ name: 'admin_phone', required: true, label: 'YOUR PHONE NUMBER' }, { children: _jsx(Input, {}) })), _jsx(Form.Item, { children: _jsx(Button, __assign({ loading: loading, type: 'primary', htmlType: 'submit' }, { children: "Submit" })) })] })) }) }))] }));
};
