import { jsx as _jsx } from "react/jsx-runtime";
import { Outlet, useLocation, useOutletContext, useParams } from 'react-router-dom';
import { Col, Row } from 'antd';
export var Licenses = function () {
    var id = useParams().id;
    var location = useLocation();
    var isNewOrSearch = location.pathname.includes('/new') || location.pathname.includes('/search');
    var apiData = useOutletContext();
    return ((isNewOrSearch || id) ? _jsx(Outlet, { context: apiData }) :
        _jsx(Row, { children: _jsx(Col, { children: "Licenses" }) }));
};
