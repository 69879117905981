var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import { useOutletContext, useParams } from "react-router-dom";
import { Table } from "antd";
import { useLicense } from "@hooks";
import { licensesLogsColumns } from "@data";
import './LicenseLogs.scss';
export var LicenseLogs = function () {
    var _a;
    var id = useParams().id;
    var apiData = useOutletContext();
    var _b = useLicense((_a = apiData === null || apiData === void 0 ? void 0 : apiData.WAPI_TOKEN) !== null && _a !== void 0 ? _a : ''), getLicenseLogs = _b.getLicenseLogs, licenseLogs = _b.licenseLogs;
    if (!id) {
        return null;
    }
    useEffect(function () {
        void getLicenseLogs(id);
    }, []);
    return (_jsxs("div", __assign({ className: 'logs' }, { children: [_jsx("h2", { children: "Logs" }), _jsx(Table, { rowKey: 'timestamp', columns: licensesLogsColumns, scroll: {
                    x: true
                }, pagination: {
                    pageSize: 20
                }, dataSource: licenseLogs })] })));
};
