var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef, useState } from 'react';
import { Outlet, useLocation, useOutletContext, useParams } from "react-router-dom";
import { Button, Col, Row, Table } from "antd";
import { useClients, useFilters } from "@hooks";
import { defaultClientsColumns } from "@data";
import { ClientsEditColumnsPopup } from "./ClientsEditColumnsPopup/ClientsEditColumnsPopup";
export var Clients = function () {
    var _a;
    var apiData = useOutletContext();
    var _b = useClients((_a = apiData === null || apiData === void 0 ? void 0 : apiData.STRIPE_KEY) !== null && _a !== void 0 ? _a : ''), clients = _b.clients, getClients = _b.getClients, loading = _b.loading, hasLessMore = _b.hasLessMore, hasNextMore = _b.hasNextMore, paginate = _b.paginate, setIsPageChanged = _b.setIsPageChanged, isPageChanged = _b.isPageChanged;
    var _c = useState([]), data = _c[0], setData = _c[1];
    var _d = useState([]), selectedRowKeys = _d[0], setSelectedRowKeys = _d[1];
    var id = useParams().id;
    var _e = useState([]), columns = _e[0], setColumns = _e[1];
    var sessionStorageDefaultCols = sessionStorage.getItem('clients-edit-default-cols');
    var defaultCols = sessionStorageDefaultCols ? JSON.parse(sessionStorageDefaultCols) : null;
    var _f = useFilters('clients'), setFilters = _f.setFilters, filters = _f.filters;
    var tableRef = useRef(null);
    var location = useLocation();
    var search = function (dataIndex, value) {
        if (value) {
            void getClients({ type: 'search', query: "".concat(dataIndex.toString(), ":'").concat(value, "'") });
        }
        else {
            void getClients({ type: 'list' });
        }
    };
    var reset = function () {
        void getClients({ type: 'list' });
    };
    var defaultColumns = defaultClientsColumns({ search: search, reset: reset }, setFilters)
        // @ts-ignore
        .filter(function (item) { return columns.includes(item.dataIndex); });
    useEffect(function () {
        void getClients({ type: 'list' });
    }, []);
    useEffect(function () {
        if (clients.length > 0) {
            setData(clients);
            setColumns((defaultCols ? defaultCols : Object.keys(clients[0])));
        }
    }, [clients]);
    var onSelectChange = function (newSelectedRowKeys) {
        setSelectedRowKeys(newSelectedRowKeys);
    };
    var tableChange = function (pagination, filters, sorter) {
        if (filters) {
            setFilters(filters);
        }
    };
    var rowSelection = {
        selectedRowKeys: selectedRowKeys,
        onChange: onSelectChange,
    };
    useEffect(function () {
        if (isPageChanged && tableRef.current) {
            tableRef.current.scrollIntoView({ behavior: 'smooth' });
            setIsPageChanged(false);
        }
    }, [isPageChanged]);
    return (location.pathname.includes('new') || id ?
        _jsx(Outlet, { context: apiData }) :
        _jsxs("div", __assign({ ref: tableRef }, { children: [_jsx("h2", { children: "Clients view" }), _jsx(Row, __assign({ justify: 'end', style: { marginBottom: '20px' } }, { children: _jsx(Col, { children: clients.length > 0 &&
                            _jsx(ClientsEditColumnsPopup, { clients: clients, setColumns: setColumns }) }) })), _jsx(Table, { dataSource: data, loading: loading, rowSelection: rowSelection, scroll: { x: true }, onChange: tableChange, columns: defaultColumns, pagination: false, rowKey: "id" }), _jsxs(Row, __assign({ className: 'mt-20' }, { children: [_jsx(Button, __assign({ disabled: !hasLessMore, onClick: function () { return paginate('prev'); }, className: 'mr-10' }, { children: "Previous" })), _jsx(Button, __assign({ disabled: !hasNextMore, onClick: function () { return paginate('next'); }, type: 'primary' }, { children: "Next" }))] }))] })));
};
