var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Route, Routes } from 'react-router-dom';
import { Home, Dashboard, License, Licenses, Demoshops, Demoshop, DemoshopNew, DemoshopLogs, LicenseNew, LicenseSearch, Clients, Client, ClientNew, Servers, Server, ServerNew, NotFound, Login } from '@pages';
export var AppRoutes = function () {
    return (_jsxs(Routes, { children: [_jsx(Route, { path: "/", element: _jsx(Home, {}) }), _jsxs(Route, __assign({ path: "/dashboard", element: _jsx(Dashboard, {}) }, { children: [_jsxs(Route, __assign({ path: "demoshops", element: _jsx(Demoshops, {}) }, { children: [_jsx(Route, { path: ":id", element: _jsx(Demoshop, {}) }), _jsx(Route, { path: "new", element: _jsx(DemoshopNew, {}) }), _jsx(Route, { path: "logs", element: _jsx(DemoshopLogs, {}) })] })), _jsxs(Route, __assign({ path: "clients", element: _jsx(Clients, {}) }, { children: [_jsx(Route, { path: ":id", element: _jsx(Client, {}) }), _jsx(Route, { path: "new", element: _jsx(ClientNew, {}) })] })), _jsxs(Route, __assign({ path: "servers", element: _jsx(Servers, {}) }, { children: [_jsx(Route, { path: ":id", element: _jsx(Server, {}) }), _jsx(Route, { path: "new", element: _jsx(ServerNew, {}) })] })), _jsxs(Route, __assign({ path: "licenses", element: _jsx(Licenses, {}) }, { children: [_jsx(Route, { path: ":id", element: _jsx(License, {}) }), _jsx(Route, { path: "new", element: _jsx(LicenseNew, {}) }), _jsx(Route, { path: "search", element: _jsx(LicenseSearch, {}) })] }))] })), _jsx(Route, { path: "/login", element: _jsx(Login, {}) }), _jsx(Route, { path: "*", element: _jsx(NotFound, {}) })] }));
};
