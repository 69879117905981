var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { LayoutWrapper } from "@components";
import { Col, Empty, Row } from "antd";
export var NotFound = function () {
    return (_jsx(LayoutWrapper, __assign({ hasSidebar: true }, { children: _jsx(Row, __assign({ justify: 'center', align: 'middle', style: { height: '100%' } }, { children: _jsx(Col, { children: _jsx(Empty, { image: "https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg", imageStyle: { height: 250 }, description: _jsxs(_Fragment, { children: [_jsx("h1", __assign({ className: 'text-center' }, { children: "404" })), _jsx("h2", __assign({ className: 'text-center mb-10' }, { children: "The page you seek doesn't exist." })), _jsxs("p", __assign({ className: 'text-center' }, { children: ["Think you're seeing this message in error?", _jsx("br", {}), "Please let us know."] }))] }) }) }) })) })));
};
