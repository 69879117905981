var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Checkbox, Divider } from "antd";
import { useEffect, useState } from 'react';
export var ClientsEditColumnsCheckboxes = function (_a) {
    var clients = _a.clients, setColumns = _a.setColumns;
    var _b = useState([]), checkboxOptions = _b[0], setCheckboxOptions = _b[1];
    var _c = useState([]), checkedList = _c[0], setCheckedList = _c[1];
    var sessionStorageDefaultColsAll = sessionStorage.getItem('clients-edit-default-cols-all');
    var sessionStorageDefaultCols = sessionStorage.getItem('clients-edit-default-cols');
    var defaultCols = sessionStorageDefaultCols ? JSON.parse(sessionStorageDefaultCols) : null;
    var checkedAll = !!(sessionStorageDefaultColsAll && sessionStorageDefaultColsAll === 'true');
    var _d = useState(checkedAll), checkAll = _d[0], setCheckAll = _d[1];
    var onCheckAllChange = function (e) {
        setCheckedList(e.target.checked ? checkboxOptions : []);
        setCheckAll(e.target.checked);
        if (e.target.checked) {
            sessionStorage.setItem('clients-edit-default-cols', JSON.stringify(checkboxOptions));
            sessionStorage.setItem('clients-edit-default-cols-all', 'true');
        }
        else {
            sessionStorage.setItem('clients-edit-default-cols-all', 'false');
            sessionStorage.removeItem('clients-edit-default-cols');
        }
        setColumns(checkboxOptions);
    };
    var onChange = function (checkedList) {
        var list = checkedList;
        setCheckedList(list);
        setCheckAll(checkedList.length === checkboxOptions.length);
        sessionStorage.setItem('clients-edit-default-cols', JSON.stringify(list));
        setColumns(list);
    };
    useEffect(function () {
        if (clients.length > 0) {
            setCheckboxOptions(Object.keys(clients[0]));
            if (defaultCols && defaultCols.length > 0) {
                setCheckedList(defaultCols);
            }
        }
    }, []);
    return (_jsxs("div", __assign({ className: 'edit-columns-popup__content' }, { children: [_jsx(Checkbox, __assign({ onChange: onCheckAllChange, checked: checkAll }, { children: "All columns" })), _jsx(Divider, { style: { margin: '10px' } }), _jsx("p", __assign({ className: 'text-grey fz-13 mb-10' }, { children: _jsx("b", { children: "Available columns" }) })), _jsx(Checkbox.Group, { style: {
                    display: 'flex',
                    flexDirection: 'column'
                }, options: checkboxOptions, value: checkedList, onChange: onChange })] })));
};
