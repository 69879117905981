import { useEffect, useState } from "react";
import { getParams } from "@helpers";
export var useFilters = function (type) {
    var localStorageFilters = sessionStorage.getItem(type + '_filtering');
    var localFilters = localStorageFilters ? JSON.parse(localStorageFilters) : {};
    var _a = useState(localFilters), filters = _a[0], setFilters = _a[1];
    var _b = useState(''), query = _b[0], setQuery = _b[1];
    var _c = useState(false), isQueryChanged = _c[0], setIsQueryChanged = _c[1];
    useEffect(function () {
        setLocalFilters(filters);
    }, [filters]);
    var clearFilters = function () {
        setFilters({});
        sessionStorage.removeItem(type + '_filtering');
    };
    var setLocalFilters = function (filters) {
        setFilters(filters);
        sessionStorage.setItem(type + '_filtering', JSON.stringify(filters));
        setQuery(getParams(filters));
    };
    return {
        filters: filters,
        query: query,
        isQueryChanged: isQueryChanged,
        setFilters: setFilters,
        setIsQueryChanged: setIsQueryChanged,
        clearFilters: clearFilters
    };
};
