var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { useState } from "react";
import axios, { AxiosError } from "axios";
import { delay, errorMessage, getDemoshopRemoveUrl, getDemoshopsCreateUrl, getDemoshopsLogUrl } from "@helpers";
export var useDemoshops = function (token) {
    var _a = useState({}), demoshops = _a[0], setDemoshops = _a[1];
    var _b = useState({}), allDemoshops = _b[0], setAllDemoshops = _b[1];
    var _c = useState({}), demoshop = _c[0], setDemoshop = _c[1];
    var _d = useState(), demoshopsLogs = _d[0], setDemoshopsLogs = _d[1];
    var _e = useState(false), loading = _e[0], setLoading = _e[1];
    var _f = useState(false), allDemoshopLoading = _f[0], setAllDemoshopLoading = _f[1];
    var _g = useState(false), demoshopsLogsLoading = _g[0], setDemoshopsLogsLoading = _g[1];
    var _h = useState(0), allDemoshopLoadingProgress = _h[0], setAllDemoshopLoadingProgress = _h[1];
    var localPaginationFiltersSorting = sessionStorage.getItem('filters_demoshops') ? sessionStorage.getItem('filters_demoshops') : null;
    var defaultPaginationFiltersSorting = localPaginationFiltersSorting ? JSON.parse(localPaginationFiltersSorting) : {
        page: 1,
        filters: {},
        sorting: {
            order_by: 'created',
            order_type: 'descend',
            dataIndex: undefined
        }
    };
    var _j = useState(defaultPaginationFiltersSorting), paginationFiltersSorting = _j[0], setPaginationFiltersSorting = _j[1];
    var getDemoshops = function (offset, query) {
        if (offset === void 0) { offset = '0,20'; }
        return __awaiter(void 0, void 0, void 0, function () {
            var response, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, axios.get("".concat(process.env.API_BASE_URL, "/status?offset=").concat(offset).concat(query ? '&' + query : ''), {
                                headers: {
                                    'x-auth': token,
                                }
                            })];
                    case 1:
                        response = _a.sent();
                        setDemoshops(response.data);
                        setLoading(false);
                        return [2 /*return*/, response.data];
                    case 2:
                        error_1 = _a.sent();
                        if (error_1 instanceof AxiosError) {
                            errorMessage(error_1);
                        }
                        else {
                            console.error('Demoshops fetch error ', error_1);
                        }
                        setLoading(false);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    var changeTerminationProtection = function (id, action) { return __awaiter(void 0, void 0, void 0, function () {
        var response, error_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, axios.put("".concat(process.env.API_BASE_URL, "clone/").concat(id), {
                            action: action,
                        }, {
                            headers: {
                                'x-auth': token,
                            }
                        })];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response.data];
                case 2:
                    error_2 = _a.sent();
                    if (error_2 instanceof AxiosError) {
                        errorMessage(error_2);
                    }
                    else {
                        console.error('Demoshops change termination protection error ', error_2);
                    }
                    setLoading(false);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var getAllDemoshops = function (limit) {
        if (limit === void 0) { limit = 100; }
        return __awaiter(void 0, void 0, void 0, function () {
            var response, data, length_1, total, numOfRequest, offset, _loop_1, i, error_3;
            var _a, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        setAllDemoshops({
                            result: [],
                            total: 0
                        });
                        _c.label = 1;
                    case 1:
                        _c.trys.push([1, 13, , 14]);
                        setAllDemoshopLoading(true);
                        return [4 /*yield*/, axios.get("".concat(process.env.API_BASE_URL, "/status?offset=0,").concat(limit), {
                                headers: {
                                    'x-auth': token,
                                }
                            })];
                    case 2:
                        response = _c.sent();
                        return [4 /*yield*/, response.data];
                    case 3:
                        data = _c.sent();
                        return [4 /*yield*/, ((_a = data === null || data === void 0 ? void 0 : data.result) === null || _a === void 0 ? void 0 : _a.length)];
                    case 4:
                        length_1 = (_b = _c.sent()) !== null && _b !== void 0 ? _b : 10;
                        return [4 /*yield*/, (data === null || data === void 0 ? void 0 : data.total)];
                    case 5:
                        total = _c.sent();
                        setAllDemoshops(response.data);
                        return [4 /*yield*/, delay(1000)];
                    case 6:
                        _c.sent();
                        if (!(total > length_1)) return [3 /*break*/, 11];
                        numOfRequest = Math.round(total / length_1);
                        offset = limit;
                        _loop_1 = function (i) {
                            var response_1, _total, reminder;
                            return __generator(this, function (_d) {
                                switch (_d.label) {
                                    case 0: return [4 /*yield*/, axios.get("".concat(process.env.API_BASE_URL, "/status?offset=").concat(offset, ",").concat(limit), {
                                            headers: {
                                                'x-auth': token,
                                            }
                                        })];
                                    case 1:
                                        response_1 = _d.sent();
                                        _total = total;
                                        if (numOfRequest - 1 === i) {
                                            reminder = total % offset;
                                            _total = total - reminder;
                                        }
                                        setAllDemoshopLoadingProgress(Math.round(offset * 100 / _total));
                                        offset = offset + limit;
                                        setAllDemoshops(function (state) {
                                            var _a;
                                            return (__assign(__assign({}, state), { result: __spreadArray(__spreadArray([], ((_a = state.result) !== null && _a !== void 0 ? _a : []), true), response_1.data.result, true) }));
                                        });
                                        return [4 /*yield*/, delay(1000)];
                                    case 2:
                                        _d.sent();
                                        return [2 /*return*/];
                                }
                            });
                        };
                        i = 0;
                        _c.label = 7;
                    case 7:
                        if (!(i < numOfRequest)) return [3 /*break*/, 10];
                        return [5 /*yield**/, _loop_1(i)];
                    case 8:
                        _c.sent();
                        _c.label = 9;
                    case 9:
                        i++;
                        return [3 /*break*/, 7];
                    case 10:
                        setAllDemoshopLoading(false);
                        return [3 /*break*/, 12];
                    case 11:
                        setAllDemoshops(response.data);
                        setAllDemoshopLoadingProgress(100);
                        setAllDemoshopLoading(false);
                        _c.label = 12;
                    case 12: return [3 /*break*/, 14];
                    case 13:
                        error_3 = _c.sent();
                        if (error_3 instanceof AxiosError) {
                            errorMessage(error_3);
                        }
                        else {
                            console.error('Demoshops fetch error ', error_3);
                        }
                        setAllDemoshopLoading(false);
                        return [3 /*break*/, 14];
                    case 14: return [2 /*return*/];
                }
            });
        });
    };
    var getDemoshop = function (id) { return __awaiter(void 0, void 0, void 0, function () {
        var response, error_4;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setLoading(true);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, axios.get("".concat(process.env.API_BASE_URL, "status/").concat(id), {
                            headers: {
                                'x-auth': token
                            }
                        })];
                case 2:
                    response = _a.sent();
                    setDemoshop(response.data);
                    setLoading(false);
                    return [3 /*break*/, 4];
                case 3:
                    error_4 = _a.sent();
                    if (error_4 instanceof AxiosError) {
                        errorMessage(error_4);
                    }
                    else {
                        console.error('Demoshop fetch error ', error_4);
                    }
                    setLoading(false);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var createDemoshop = function (data) {
        setLoading(true);
        return new Promise(function (resolve, reject) { return __awaiter(void 0, void 0, void 0, function () {
            var response, error_5;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        setLoading(true);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 5, , 7]);
                        return [4 /*yield*/, axios.post(getDemoshopsCreateUrl(), data, {
                                headers: {
                                    'x-auth': token
                                }
                            })];
                    case 2:
                        response = _a.sent();
                        return [4 /*yield*/, resolve(response.data)];
                    case 3:
                        _a.sent();
                        return [4 /*yield*/, setDemoshop(response.data)];
                    case 4:
                        _a.sent();
                        setLoading(false);
                        return [3 /*break*/, 7];
                    case 5:
                        error_5 = _a.sent();
                        return [4 /*yield*/, reject({
                                error: true,
                                message: error_5
                            })];
                    case 6:
                        _a.sent();
                        setLoading(false);
                        return [3 /*break*/, 7];
                    case 7: return [2 /*return*/];
                }
            });
        }); });
    };
    var removeDemoshop = function (id) { return __awaiter(void 0, void 0, void 0, function () {
        var error_6;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setLoading(true);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, axios.delete(getDemoshopRemoveUrl(id), {
                            headers: {
                                'x-auth': token
                            }
                        })];
                case 2:
                    _a.sent();
                    void getDemoshops();
                    setLoading(false);
                    return [3 /*break*/, 4];
                case 3:
                    error_6 = _a.sent();
                    if (error_6 instanceof AxiosError) {
                        errorMessage(error_6);
                    }
                    else {
                        console.error('Demoshop remove error', error_6);
                    }
                    setLoading(false);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var getDemoshopsLogs = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, error_7;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setDemoshopsLogsLoading(true);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, axios.get(getDemoshopsLogUrl(), {
                            headers: {
                                'x-auth': token
                            }
                        })];
                case 2:
                    response = _a.sent();
                    setDemoshopsLogs(response.data);
                    setDemoshopsLogsLoading(false);
                    return [3 /*break*/, 4];
                case 3:
                    error_7 = _a.sent();
                    if (error_7 instanceof AxiosError) {
                        errorMessage(error_7);
                    }
                    else {
                        console.error('Demoshops logs get error', error_7);
                    }
                    setDemoshopsLogsLoading(false);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    // useEffect(() => {
    //     sessionStorage.setItem('filters_demoshops', JSON.stringify(paginationFiltersSorting));
    // }, [paginationFiltersSorting]);
    return {
        demoshops: demoshops,
        allDemoshops: allDemoshops,
        demoshop: demoshop,
        demoshopsLogs: demoshopsLogs,
        loading: loading,
        demoshopsLogsLoading: demoshopsLogsLoading,
        allDemoshopLoading: allDemoshopLoading,
        allDemoshopLoadingProgress: allDemoshopLoadingProgress,
        paginationFiltersSorting: paginationFiltersSorting,
        getAllDemoshops: getAllDemoshops,
        createDemoshop: createDemoshop,
        getDemoshops: getDemoshops,
        getDemoshop: getDemoshop,
        getDemoshopsLogs: getDemoshopsLogs,
        removeDemoshop: removeDemoshop,
        setPaginationFiltersSorting: setPaginationFiltersSorting,
        changeTerminationProtection: changeTerminationProtection
    };
};
