import { create } from 'zustand';
export var useAuthStore = create(function (set, getState) {
    var storedUser = localStorage.getItem('user');
    var user = storedUser ? JSON.parse(storedUser) : null;
    var isAuthUser = false;
    if (user && user.hasOwnProperty('userDataKey')) {
        isAuthUser = localStorage.getItem(user.userDataKey) ? true : false;
    }
    return ({
        isAuth: isAuthUser,
        setAuthenticated: function () { return set(function () { return ({ isAuth: true }); }); },
        setUnAuthenticated: function () { return set(function () { return ({ isAuth: false }); }); },
    });
});
