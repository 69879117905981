var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { logOut } from "@helpers";
import { Menu, Layout, Row, Col } from 'antd';
import { NavLink, useLocation } from 'react-router-dom';
import { useAuthStore } from '@state';
import logo from '@static/images/wetail_logo_red-2.png';
export var Header = function () {
    var Header = Layout.Header;
    var _a = useAuthStore(function (state) { return state; }), isAuth = _a.isAuth, setUnAuthenticated = _a.setUnAuthenticated;
    var location = useLocation();
    var defaultKeys = [location.pathname === '/' ? 'home' : location.pathname.replace('/', '')];
    var firstItems = [
        {
            label: (_jsx(NavLink, __assign({ to: "/dashboard" }, { children: "Dashboard" }))),
            key: 'dashboard',
        }
    ];
    var secondItems = [
        {
            label: (isAuth && _jsx(NavLink, __assign({ to: "logged-out", onClick: function (e) {
                    e.preventDefault();
                    void logOut(setUnAuthenticated);
                } }, { children: "Logged out" }))),
            key: 'login',
        },
    ];
    return (_jsx(Header, __assign({ className: "header" }, { children: _jsxs(Row, __assign({ justify: "space-between", align: "middle" }, { children: [_jsx(Col, __assign({ flex: "0 1 280px" }, { children: _jsx(NavLink, __assign({ style: { color: '#ffffff' }, to: "/" }, { children: _jsx("img", { style: { width: '100px', padding: '10px' }, src: logo, alt: "Logo" }) })) })), isAuth &&
                    _jsx(Col, __assign({ flex: "1" }, { children: _jsx(Menu, { theme: "dark", mode: "horizontal", items: firstItems, defaultSelectedKeys: defaultKeys }) })), _jsx(Col, __assign({ flex: "0 1" }, { children: _jsx(Menu, { theme: "dark", mode: "horizontal", items: secondItems }) }))] })) })));
};
