var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { formatDate } from "@helpers";
import dayjs from 'dayjs';
import { useEffect, useState } from "react";
import { FilterOutlined } from "@ant-design/icons";
import { Button, DatePicker, Row } from "antd";
var RangePicker = DatePicker.RangePicker;
var dateFormat = 'YYYY-MM-DD';
export function useDateRange(_a) {
    var dataIndex = _a.dataIndex, setPaginationFiltersSorting = _a.setPaginationFiltersSorting, currentView = _a.currentView;
    var sessionFilters = sessionStorage.getItem("filters_".concat(currentView)) ? sessionStorage.getItem("filters_".concat(currentView)) : null;
    var defaultFilters = sessionFilters ? JSON.parse(sessionFilters) : {};
    var _b = useState(false), isRangeChanged = _b[0], setIsRangeChanged = _b[1];
    var _c = useState([]), dateStrings = _c[0], setDateStrings = _c[1];
    var _d = useState(false), filtered = _d[0], setFiltered = _d[1];
    var _e = useState([dayjs(formatDate(), dateFormat), dayjs(formatDate(), dateFormat)]), rangeValue = _e[0], setRangeValue = _e[1];
    var handleClick = function (confirm, dataIndex) {
        if (!isRangeChanged) {
            return;
        }
        if (!dateStrings[0] || !dateStrings[1]) {
            return;
        }
        setPaginationFiltersSorting(function (prevState) {
            var _a;
            // @ts-ignore
            var range = (_a = {},
                _a[(dataIndex === null || dataIndex === void 0 ? void 0 : dataIndex.toString()) + '_from'] = dateStrings[0] ? dateStrings[0].toString() : null,
                _a[(dataIndex === null || dataIndex === void 0 ? void 0 : dataIndex.toString()) + '_to'] = dateStrings[1] ? dateStrings[1].toString() : null,
                _a);
            return __assign(__assign({}, prevState), { filters: __assign(__assign({}, prevState.filters), range) });
        });
        setFiltered(true);
        confirm();
        setIsRangeChanged(false);
    };
    var handleReset = function (clearFilters, confirm) {
        setDateStrings([]);
        setFiltered(false);
        setRangeValue([dayjs(formatDate(), dateFormat), dayjs(formatDate(), dateFormat)]);
        clearFilters();
        confirm();
        setPaginationFiltersSorting(function (prevState) {
            var _a = prevState.filters, _b = dataIndex.toString() + '_from', date1 = _a[_b], _c = dataIndex.toString() + '_to', date2 = _a[_c], rest = __rest(_a, [typeof _b === "symbol" ? _b : _b + "", typeof _c === "symbol" ? _c : _c + ""]);
            return __assign(__assign({}, prevState), { filters: rest });
        });
    };
    var rangePickerChange = function (dates, dateStrings, setSelectedKeys) {
        setIsRangeChanged(true);
        setDateStrings(dateStrings);
    };
    return ({
        filterDropdown: function (_a) {
            var setSelectedKeys = _a.setSelectedKeys, confirm = _a.confirm, clearFilters = _a.clearFilters;
            useEffect(function () {
                var valueFrom = defaultFilters.filters && defaultFilters.filters[dataIndex.toString() + '_from'] ? defaultFilters.filters[dataIndex.toString() + '_from'] : null;
                var valueTo = defaultFilters.filters && defaultFilters.filters[dataIndex.toString() + '_to'] ? defaultFilters.filters[dataIndex.toString() + '_to'] : null;
                if (valueFrom && valueTo) {
                    setRangeValue([dayjs(valueFrom, dateFormat), dayjs(valueTo, dateFormat)]);
                    setFiltered(true);
                    confirm();
                }
            }, []);
            return (_jsxs("div", __assign({ style: { padding: 15 }, onKeyDown: function (e) { return e.stopPropagation(); } }, { children: [_jsx(RangePicker, { format: dateFormat, defaultValue: rangeValue, onChange: function (dates, dateStrings) {
                            rangePickerChange(dates, dateStrings, setSelectedKeys);
                        } }), _jsxs(Row, __assign({ justify: 'end', style: { marginTop: '10px' } }, { children: [_jsx(Button, __assign({ onClick: function () {
                                    handleClick(confirm, dataIndex);
                                }, type: 'primary' }, { children: "Filter" })), _jsx(Button, __assign({ onClick: function () {
                                    if (clearFilters) {
                                        handleReset(clearFilters, confirm);
                                    }
                                }, style: { marginLeft: '10px' } }, { children: "Reset" }))] }))] })));
        },
        filterIcon: function () { return (_jsx(FilterOutlined, { style: { color: filtered ? '#1677ff' : undefined } })); }
    });
}
